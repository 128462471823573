<template>
  <div id="misc">
    <v-row class="mb-3" align="center">
      <v-col cols="12" md="4">
        <v-text-field
          rounded
          dense
          outlined
          v-model="query"
          :prepend-inner-icon="icons.mdiMagnify"
          class="app-bar-search flex-grow-0"
          hide-details
        ></v-text-field>
      </v-col>
      <v-col cols="12" md="3">
        <v-select
          v-model="embeddingField"
          :items="this.embeddingFields"
          label="Embedding Field"></v-select>
      </v-col>
      <v-col cols="12" md="2">
        <v-switch
          v-model="isSemantic"
          inset
          style="font-size: 10px"
          label="Semantic Search"
        ></v-switch>
      </v-col>
      <v-col cols="12" md="3">
        <vc-date-picker v-model="dateRange" is-range>
          <template v-slot="{ inputValue, inputEvents }">
              <input
                :value="inputValue.start"
                v-on="inputEvents.start"
                class="dateInput"
                style="max-width: 100px"/>
              <v-icon>{{ icons.mdiArrowRight }}</v-icon>
              <input
                :value="inputValue.end"
                v-on="inputEvents.end"
                style="max-width: 100px"
                class="ms-1 dateInput"/>
          </template>
        </vc-date-picker>
      </v-col>
    </v-row>
    <v-progress-linear
      v-show="isLoading"
      indeterminate
      class="mb-2"
    ></v-progress-linear>
    <v-simple-table dense>
      <template v-slot:default>
        <thead>
        <tr>
          <th class="">
            Title
          </th>
          <th class="">
            Publisher
          </th>
          <th class="">
            Published
          </th>
        </tr>
        </thead>
        <tbody>
        <tr
          v-for="item in articles"
          :key="item._id">
          <td class="">
            <strong>{{ item.title }}</strong>
            <div class="small" v-for="highlight in item.highlight" :key="highlight" v-html="highlight"></div>
          </td>
          <td class="">
            {{ item.source }}
          </td>
          <td class="">
            {{ moment(item.published).format('ll') }}
          </td>
        </tr>
        </tbody>
      </template>
    </v-simple-table>
  </div>
</template>

<script>

import axios from 'axios'
import config from "@/utils/config";
import {mdiMagnify, mdiArrowRight} from '@mdi/js'
import _ from 'lodash'
//import DatePicker from 'v-calendar/lib/components/date-picker.umd'

let moment = require("moment");
moment.locale('de-DE');


export default {
  components: {},
  setup() {
    return {
      embeddingField: null,
      embeddingFields: null,
      articles: null,
      query: null,
      isLoading: false,
      isSemantic: true,
      dateRange: {
        start: new Date(2019, 1, 1),
        end: new Date(),
      },
      moment: moment,
      icons: {
        mdiMagnify,
        mdiArrowRight
      }
    }
  },
  mounted() {
    axios.get(`${config.SERVER_URL}/embeddings/fields`).then((data) => {
      this.embeddingFields = data.data.embeddingFields;
      this.$forceUpdate();
      this.embeddingField = this.embeddingFields[0];
    });

    this.getArticles();
  },
  watch: {
    query() {
      if (this.query != null && this.query !== '') {
        this.search()
      }
    },
    isSemantic() {
      if (this.query != null && this.query !== '') {
        this.search()
      }
    }
  },
  methods: {
    getArticles: function () {
      axios.get(`${config.SERVER_URL}/articles`).then((data) => {
        this.articles = data.data.articles
      });
    },
    search: _.debounce(function () {
      this.isLoading = true
      axios.get(`${config.SERVER_URL}/search`, {
        params: {
          'q': this.query,
          'type': this.isSemantic ? 'semantic' : 'kw',
          'start': this.dateRange.start,
          'end': this.dateRange.end,
          'embedding_field': this.embeddingField
        }
      }).then((data) => {
        this.articles = null;
        this.articles = data.data.result
        this.isLoading = false
        //console.log(this.articles)
      });
    }, 500)
  }
}
</script>

<style lang="scss">

.dateInput {
  padding: 5px;
  border: 2px solid #ccc;
  -webkit-border-radius: 5px;
  border-radius: 5px;
}

input[type=text]:focus {
  border-color: #333;
}

em {
  background: #ffd235;
}

.small {
  font-size: 0.75rem;
}

</style>
